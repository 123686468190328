import messageService from '../../api/messages'
const state = {
  messages: []
}

const mutations = {
  setMessages (state, messages) {
    state.messages = messages
  }
}

const actions = {
  getMessages ({ commit }) {
    return new Promise((resolve, reject) => {
      messageService.getMessages().then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getContactMessages ({ commit }, contactId) {
    return new Promise((resolve, reject) => {
      messageService.getMessagesByContact(contactId).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendMessage ({ commit }, message) {
    return new Promise((resolve, reject) => {
      messageService.sendMessage(message).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
