import chatService from '../../api/chats'
const state = {
  chats: []
}

const mutations = {
  setContacts (state, chats) {
    state.chats = chats
  }
}

const actions = {
  getChats ({ commit }) {
    return new Promise((resolve, reject) => {
      chatService.getChats().then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
