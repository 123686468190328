import http from '../http'

export default {
  getMessages () {
    return http.get('/message')
  },
  getMessagesByContact (contactId) {
    return http.get(`/message/${contactId}`)
  },
  sendMessage (message) {
    console.log(message)
    return http.post('/message', message)
  }
}
